import { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import PDF from "../components/pdf";
import { UserContext } from "../context/UserContext";

const Report = () => {
  const url = new URL(window.location.href);
  let reportId = url.searchParams.get("reportId") ? url.searchParams.get("reportId") : 0;

  const { user } = useContext(UserContext);

  const [data, setData] = useState();
  const [categoryId, setCategoryId] = useState(1);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [currentMainCategoryIndex, setCurrentMainCategoryIndex] = useState(localStorage.getItem("mainCategoryIndex")?parseInt(localStorage.getItem("mainCategoryIndex")): -1);
  const [categories, setCategories] = useState([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(localStorage.getItem("categoryIndex")?parseInt(localStorage.getItem("categoryIndex")):0);
  const [report, setReport] = useState({})
  const [generatedReport, setGeneratedReport] = useState()
  const [isFinished, setIsFinished] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [sendReport, setSendReport] = useState(false)
  const navigate = useNavigate();
 

  function getCategoryFieldsByReport(categoryId, categoriesLoc,direction="forward",report_type="") {
    fetch(`api/report_fields_by_report/${reportId}/${categoryId}/?report_type=${report_type}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        let categoryData = {};
        let fields = [];

        /*  response.categories.map((category) => {
          // fields.push({ label: field.label , id:field.id,remark:"" });
           category.remark=""
           saveReportField(reportId,category.id,false,"")
         });*/
        //categoryData.push({"category" : response.category, "fields" : fields})
        categoryData.mainCategory = response.mainCategory;
        categoryData.categories = response.categories;
        setData(categoryData);
        
        if(direction == "backward")
          setCurrentCategoryIndex(response.categories.length-1);
        else
          setCurrentCategoryIndex(0);
        //  setCurrentCategoryIndex(0);
       // else
          
        // setCurrentCategoryIndex(index);
        //getCategoryFieldsByReport(categoriesLoc[index+1].id,categoriesLoc);
      })
      .catch((error) => {
        try{
          if(direction == "backward")
            getEmptyCategoryFields(categoriesLoc[currentMainCategoryIndex-1].id,direction);
          else
          getEmptyCategoryFields(categoriesLoc[currentMainCategoryIndex+1].id,direction);
        }catch (e){
          getEmptyCategoryFields(categoriesLoc[currentMainCategoryIndex].id,direction);
        }
        
      
      });
  }

  function getEmptyCategoryFields(categoryId,direction="forward") {
    fetch(`api/fields_by_category/${categoryId}/?report_type=${report.report_type}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
       
        let categoryData = {};
        let fields = [];

        response.categories.map((category) => {
          // fields.push({ label: field.label , id:field.id,remark:"" });
          category.remark = "";
          saveReportField(reportId, category.id, false, "");
        });
        //categoryData.push({"category" : response.category, "fields" : fields})
        categoryData.mainCategory = response.mainCategory;
        categoryData.categories = response.categories;
        // console.log(categoryData)
        //setData((oldData) => [...oldData, categoryData]);
        //console.log(data);

        setData(categoryData);
        if(direction == "backward")
         // setCurrentCategoryIndex(0);
        //else
          setCurrentCategoryIndex(response.categories.length-1);
      });
  }

  useEffect(() => {
    //getEmptyCategoryFields(1)
    // getEmptyCategoryFields(2)
    // getEmptyCategoryFields(3)
    let categoriesLoc = [];
    const lastReportId  = localStorage.getItem("lastReportId") ? localStorage.getItem("lastReportId") : 0;
    let currIndex, currMainCategoryIndex;
    if(lastReportId != reportId || reportId==0){
      localStorage.setItem("lastReportId",reportId)
      localStorage.setItem("mainCategoryIndex",-1)
      localStorage.setItem("categoryIndex",0)
      setCurrentMainCategoryIndex(-1)
      setCurrentCategoryIndex(0)
      currIndex = 0;
      currMainCategoryIndex = -1;
    }else{
      currIndex = localStorage.getItem("categoryIndex") ? localStorage.getItem("categoryIndex") : 0;
      currMainCategoryIndex = localStorage.getItem("mainCategoryIndex") ? localStorage.getItem("mainCategoryIndex") : -1;
    }
    fetch(`api/vehicles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);

        setVehicles(response);
      })
      .catch((error) => {
        console.error("There was an error fetching the vehicles!", error);
      });

    fetch(`api/maincategories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
       

        setCategories(response);
        categoriesLoc = response;
    

      if (reportId) {
        fetch(`api/report/${reportId}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          credentials: "include",
        })
          .then((response) => response.json())
          .then((response) => {
            setSelectedVehicleId(response.vehicle);
            setReport(response)
            if (currMainCategoryIndex>-1){
            getCategoryFieldsByReport(categoriesLoc[currMainCategoryIndex].id, categoriesLoc,"forward",response.report_type);
            }
          })
          .catch((error) => {
            console.error("There was an error fetching the report!", error);
          });

          setIsNew(false)
        }
    })
    .catch((error) => {
      console.error("There was an error fetching the categories!", error);
    });
  }, []);

  useEffect(()=>{
      localStorage.setItem("mainCategoryIndex",currentMainCategoryIndex)
  },[currentMainCategoryIndex])

  useEffect(()=>{
    localStorage.setItem("categoryIndex",currentCategoryIndex)
  },[currentCategoryIndex])

  const handleHeadInputChange=(event)=>{
    const value=event.target.value;
    const field = event.target.id;

    setReport(prevReport =>({ ...prevReport, [field] : value}))

  }

  const handleChange = (event) => {
    const vehicleId = event.target.value;
    setSelectedVehicleId(vehicleId);
    // saveReport();
    // onSelectVehicle(vehicleId);  // Pass the selected vehicle ID to the parent component
  };

  useEffect(() => {
   // if (selectedVehicleId && reportId == 0) saveReport();
  }, [selectedVehicleId]);

  function saveReport() {
    fetch("api/save_report/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        vehicle_id: parseInt(selectedVehicleId),
        user_id: localStorage.getItem("user"), //TODO change user id from context
        is_closed: report.is_closed ? report.is_closed : false,
        km:document.getElementById("km").value,
        motor_hours:document.getElementById("motor_hours").value, 
        ledder_hours:document.getElementById("ledder_hours").value,
        id : reportId
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const params = new URLSearchParams(window.location.search);
        params.set("reportId", data.report_id);
        navigate(`?${params.toString()}`, { replace: true });
        reportId = data.report_id;
        setReport(data.report);
        //getEmptyCategoryFields(categories[currentMainCategoryIndex].id);
        // setCurrentCategoryIndex(currentCategoryIndex+1)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function changeRemark(event) {
    const el = event.target;
    const fieldId = el.dataset.fieldid;
    const newValue = el.value;
    /* const index = data[currentMainCategoryIndex].categories.findIndex(item => item.fields.find(f => f.id == fieldId));
    if (index > -1) {
      const newFields = data[index].fields.map(f => {
        if (f.id == fieldId) {
          return {...f, remark: newValue}
        }
        return f;
      });
      setData(
        data.map((item, i) => {
          if (i == index) {
            return {...item, fields: newFields}
          }
          return item;
        })
      );
    }*/
    data.categories[currentCategoryIndex].remark = newValue;
    setData((prevData) => ({
      ...prevData,
      categories: prevData.categories.map((category, i) => (i === currentCategoryIndex ? { ...category, remark: newValue } : category)),
    }));
    // console.log(event.target.value, event.target.dataset.fieldid);
    saveReportField(reportId, event.target.dataset.fieldid, false, event.target.value);
  }

  function changeCheckbox(event) {
    const el = event.target;
    const fieldId = el.dataset.fieldid;
    const newValue = el.checked ? true : false;
    data.categories[currentCategoryIndex].is_checked = newValue;
    setData((prevData) => ({
      ...prevData,
      categories: prevData.categories.map((category, i) => (i === currentCategoryIndex ? { ...category, is_checked: newValue } : category)),
    }));
    // console.log(event.target.value, event.target.dataset.fieldid);
    saveReportField(reportId, event.target.dataset.fieldid, newValue, document.getElementById(fieldId).value);
  }

  function changeFieldText(event) {
    const el = event.target;
    const fieldId = parseInt(el.dataset.fieldid, 10); // Get the field ID from the dataset
    const newValue = el.value;
    const reportFieldId = parseInt(el.dataset.reportfieldid)
  
    setData((prevData) => ({
      ...prevData,
      categories: prevData.categories.map((category, categoryIndex) =>
        categoryIndex === currentCategoryIndex
          ? {
              ...category,
              fields: category.fields.map((field) =>
                field.id === fieldId ? { ...field, text: newValue } : field
              ),
            }
          : category
      ),
    }));
    fetch("api/save-reportfieldtext",{
      method : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        report_field_id: reportFieldId,
        field_id: fieldId,
        text : newValue
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  

  function saveReportField(reportId, fieldId, checkbox, remark) {
    fetch("api/save_report_field/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        report_id: reportId,
        field_id: fieldId,
        is_checked: checkbox,
        remarks: remark,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function nextCategory() {
    if (selectedVehicleId && currentMainCategoryIndex==-1 ){
      if (document.getElementById("km").value=="" || document.getElementById("motor_hours").value=="" || document.getElementById("ledder_hours").value==""){
          alert("Az adatok megadása kötelező!");
          return;

      } 
      saveReport();
    } 
   
    if(currentMainCategoryIndex==-1){
      setCurrentMainCategoryIndex(0);
      setCurrentCategoryIndex(0);
      if ( reportId != 0){
        getCategoryFieldsByReport(categories[currentMainCategoryIndex+1].id,categories,"forward",report.report_type);
      } else {
        getEmptyCategoryFields(categories[currentMainCategoryIndex + 1].id);
      }
      return;
    }

    if(!document.getElementById("checkbox"+data.categories[currentCategoryIndex].id).checked && document.getElementById(data.categories[currentCategoryIndex].id).value==""){
      alert("Ellenőrzés jóváhagyása vagy megjegyzés megadás kötelező!")
      return;
    }
    if (currentMainCategoryIndex+1==categories.length && currentCategoryIndex+1==data.categories.length){ 
      setIsFinished(true);
      return
    }
    if (!data || data.categories.length == currentCategoryIndex + 1) {
     // console.log("kategória váltás:", currentMainCategoryIndex,currentCategoryIndex);
      if ( reportId != 0){
        setCurrentMainCategoryIndex(currentMainCategoryIndex + 1);
       // setCurrentCategoryIndex(0);
        getCategoryFieldsByReport(categories[currentMainCategoryIndex+1].id, categories,"forward",report.report_type);
      } //else {
       // getEmptyCategoryFields(categories[currentMainCategoryIndex + 1].id);
      //}
     
    } else {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    }
  }

  function prevCategory() {
    if (isFinished) {
      setIsFinished(false);
      return;
    }
    if (currentMainCategoryIndex == 0 && currentCategoryIndex == 0) {
        setCurrentMainCategoryIndex(-1);
        return;
    }
      
    if (!data || currentCategoryIndex == 0) {
     
      setCurrentMainCategoryIndex(currentMainCategoryIndex - 1);
      if ( reportId != 0){
        getCategoryFieldsByReport(categories[currentMainCategoryIndex-1].id, categories,"backward",report.report_type);
      } else {
        getEmptyCategoryFields(categories[currentMainCategoryIndex -1].id,"backward");
      }
    } else {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
    }
  }

  function handleChangeCloseComment(event) {
    const value= event.target.value;
    
    setReport(prevReport =>({...prevReport, close_status : value}));
    if (generatedReport)
      setGeneratedReport(prevReport =>({...prevReport, header: {...prevReport.header, close_status : value}}));
  }

  useEffect(() => {
    if(report.is_closed && isFinished){
      setTimeout(() => {
        
      
    
      }, 100);
    }
  
  },[report])

  function reGeneratePDF(){
    closeReport();
    setTimeout(() => {
      alert("PDF újragenerálása sikeres!");
    }, 2000);
    
  }

  function generatePDF() {
    fetch("api/full_report/"+reportId+"/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        
        response.header.close_status = document.querySelector("input[name='close_comment']:checked").value;
      //  console.log(response)
       // closeReport();
        setGeneratedReport(response);
        setSendReport(true)
        //alert("PDF módosítása sikeres!")
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    
  }

  useEffect(() => {
    
    if(sendReport){
      
      setTimeout(() => {
        
      
          fetch("api/generate-pdf/"+reportId, {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              html: document.getElementById("pdf-container").innerHTML,
            }),
            credentials: "include",
          })
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob])); // Create a URL for the Blob
              setSendReport(false);
          /*   const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "jegyzokonyv_"+reportId+".pdf"); // Set the desired filename

              // Append the link to the body and trigger the download
              document.body.appendChild(link);
              link.click();*/
            })
            .catch((error) => {
              console.error("Error:", error);
            });
      },2000)
    }
  },[sendReport])
  function closeReport() {
    if (!document.querySelector("input[name='close_comment']:checked") ){
      alert("Megjegyzés megadás Kötelező!");  
      return;
    }
    
    fetch("api/close_report/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        close_comment: document.querySelector("input[name='close_comment']:checked").value,
        is_closed : true,
        report_id : reportId
      }),
      credentials: "include", 
    })
    .then((response) => response.json())
    .then((data) => {
      setReport(prevReport =>({...prevReport, is_closed : true}));
      setTimeout(() => {
        
          generatePDF();
      
      }, 100);
    })
    
  }

  return (
    <>
      <div className="container" id="container">
      
        
        <div className="table" id="table">
          
          <div id="head-data" style={{ display: currentMainCategoryIndex == -1 ? "flex" : "none",flexDirection:"column" }}>
          <div className="category row">Alapadatok</div>
          <div className="row ">
            <div className="form-group">
            <label htmlFor="vehicle-select">Jármű</label>
            {isNew  ?
              
              
              <select id="vehicle-select" className="input-control" value={selectedVehicleId} onChange={handleChange}>
                <option value="" disabled>
                  Válassz egy járművet
                </option>
                {vehicles &&
                  vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.display_name}
                    </option>
                  ))}
              </select>
              
              : <b className="">{report.vehicle}</b>
            }
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label htmlFor="vehicle">Km</label>
              <input type="text" className="input-control" id="km" name="km" disabled={user && !user.can_generatepdf && report.is_closed} value={report.km} onChange={handleHeadInputChange}/>
              </div>
              <div className="form-group">
              <label htmlFor="motor_hours">Üzemóra</label>
              <input type="text" className="input-control" name="motor_hours" disabled={user && !user.can_generatepdf && report.is_closed} id="motor_hours" value={report.motor_hours} onChange={handleHeadInputChange}/>
            </div>
            <div className="form-group">
              <label htmlFor="ledder_hours">Létra üzemóra</label>
              <input type="text" className="input-control" name="ledder_hours"  disabled={user && !user.can_generatepdf && report.is_closed} id="ledder_hours" value={report.ledder_hours} onChange={handleHeadInputChange}/>
            </div>
            </div>
          </div>
          {(data && currentMainCategoryIndex != -1 && !isFinished) && (
            <>
              <div className="category row">{data.mainCategory}</div>

              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>{data.categories[currentCategoryIndex].category}</div>
                <div className="col">
                  {data.categories[currentCategoryIndex].fields.map((field) => (
                    <div className="label" key={field.id}>{field.input && <input type="text" className="input-control" disabled={user && !user.can_generatepdf && report.is_closed} data-fieldid={field.id} id={field.id} data-reportfieldid={data.categories[currentCategoryIndex].report_field_id} onChange={changeFieldText} value={field.text}  />}{field.label}</div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
              <label className="checkbox-container"> OK
                           <input type="checkbox" disabled={user && !user.can_generatepdf && report.is_closed} data-fieldid={data.categories[currentCategoryIndex].id} id={"checkbox"+data.categories[currentCategoryIndex].id} checked={data.categories[currentCategoryIndex].is_checked} onChange={changeCheckbox} />
                            <span className="checkmark"></span>
                        </label>
                </div>
                <div className="col">
                  <div className="form-group">
                    
                    <input placeholder="Megjegyzés" disabled={user && !user.can_generatepdf && report.is_closed} style={{width: "100%",marginTop:"0"}} className="input-control" data-fieldid={data.categories[currentCategoryIndex].id} value={data.categories[currentCategoryIndex].remark} id={data.categories[currentCategoryIndex].id} onChange={changeRemark} type="text" />
                  </div>
                </div> 
              </div>
            </>
          )}
          <div className="close_comment_div" style={{display: isFinished ? "flex" : "none",flexDirection: "column"}}>
          <div className="category row">Lezárás</div>
                <div className="row" style={{flexDirection: "column",alignItems: "flex-start"}}>
                   <div className="checkbox ">
                      <input disabled={user && !user.can_generatepdf && report.is_closed} type="radio" value="Létra biztonságosan üzemeltethető" onChange={handleChangeCloseComment} checked={report.close_status == "Létra biztonságosan üzemeltethető"}  name="close_comment"/>
                      Létra biztonságosan üzemeltethető
                  </div>
                  <div className="checkbox ">
                      <input disabled={user && !user.can_generatepdf && report.is_closed} type="radio"  value="Létra csak feltételekkel üzemeltethető" onChange={handleChangeCloseComment} checked={report.close_status == "Létra csak feltételekkel üzemeltethető"} name="close_comment"/>
                      Létra csak feltételekkel üzemeltethető
                  </div>
                  <div className="checkbox ">
                      <input disabled={user && !user.can_generatepdf &&report.is_closed} type="radio"  value="A létra nem biztonságos" onChange={handleChangeCloseComment} checked={report.close_status == "A létra nem biztonságos"} name="close_comment"/>
                      A létra nem biztonságos
                  </div>

                  {!report.is_closed && <button className="button" id="close-report" style={{marginTop:"30px",visibility: (currentMainCategoryIndex != -1 || (data && currentMainCategoryIndex < categories.length-1 && currentCategoryIndex < data.categories.length-1)) ? "visible" :"hidden"}} onClick={closeReport}>
                     Lezár
                  </button>}

                  {(user && user.can_generatepdf && report.is_closed) && <button className="button" id="genreate-pdf" style={{marginTop:"30px",visibility: (currentMainCategoryIndex != -1 || (data && currentMainCategoryIndex < categories.length-1 && currentCategoryIndex < data.categories.length-1)) ? "visible" :"hidden"}} onClick={reGeneratePDF}>
                     PDF újra generálása
                  </button>}
                  </div>
          </div>
          {/*  <div className="row">
                  <div className="label">{field.label}</div>
                  <div className="checkbox">
                    <input type="checkbox" />
                  </div>
                  <div className="remarks">
                    <input data-fieldid={field.id} value={field.remark} id={field.id} onChange={changeRemark} type="text" />
                  </div>
                </div>*/}
        </div>
        <div className="button-container">
        
            <button   style={{visibility: (currentMainCategoryIndex > -1 || currentCategoryIndex > 0) ? "visible" :"hidden"}}  className="button" id="prevButton" onClick={prevCategory}>
              
            </button>
          
          {!isFinished && <button className="button" id="nextButton" onClick={nextCategory}>
           
          </button>}
        </div>
      </div>

     

       <div id="pdf-container" style={{display: "none"}}>{(generatedReport && generatedReport.header.vehicle) && report.is_closed && <PDF generatedReport={generatedReport}  reportId={reportId}></PDF>}</div>
    </>
  );
};

export default Report;
